import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'scan',
      component: () => import('@/views/scan/Scan.vue'),
      meta: {
        requiresAuth: false,
        pageTitle: 'Scan',
        breadcrumb: [
          {
            text: 'Scan',
            active: true,
          },
        ],
      },
    },
    {
      path: '/scan-result/:id',
      name: 'scan-result',
      component: () => import('@/views/scan/ScanResult.vue'),
      meta: {
        requiresAuth: false,
        pageTitle: 'Scan-result',
        breadcrumb: [
          {
            text: 'scan-result',
            active: true,
          },
        ],
      },
      props: true
    },
    {
      path: '/scanned-results',
      name: 'scanned-results',
      component: () => import('@/views/scan/ScanedResults.vue'),
      meta: {
        requiresAuth: false,
        pageTitle: 'Scanned-results',
        breadcrumb: [
          {
            text: 'scanned-results',
            active: true,
          },
        ],
      },
    },
    {
      path: '/rugscan-token',
      name: 'rugscan-token',
      component: () => import('@/views/rugscanToken/RugscanToken.vue'),
      meta: {
        requiresAuth: false,
        pageTitle: 'Rugscan-token',
        breadcrumb: [
          {
            text: 'Rugscan-token',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        requiresAuth: false,
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/auth/Register.vue'),
      meta: {
        requiresAuth: false,
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        requiresAuth: false,
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

function is_authenticated() {
  const token = localStorage.getItem("token");
  if (token == "null" || token == null || token == "") {
    return false;
  } else {
    return true;
  }
}
/**
 * Router Authentication Guard
 */
router.beforeEach((to, from, next) => {
  const withoutAuth = ["login", "register", "scanned-results", "scan", "error-404", "social-login", "scan-result", "rugscan-token"];
  if (withoutAuth.includes(to.name)) {
    next();
  }
  else if (!to.meta.requiresAuth) {
    is_authenticated() ? next(to.name) : next();
  } else {
    is_authenticated() ? next() : next({ name: "login" });
  }
});

export default router
