import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'
import numeral from 'numeral'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import i18n from '@/libs/i18n'
// import style
import 'swiper/css/swiper.css'
import VueAuthenticate from 'vue-authenticate'
import moment from 'moment-timezone';
import vGoogleTranslate from 'v-google-translate';

Vue.use(vGoogleTranslate);
// Vue.use(moment);
moment.tz.setDefault('America/Chicago');

Vue.use(VueAuthenticate, {
  baseUrl: 'http://localhost:3000', // Your API domain
  
  providers: {
    github: {
      clientId: '',
      redirectUri: 'http://localhost:8080/auth/callback' // Your client app URL
    }
  }
})

Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts);

import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing);

Vue.component('apexchart', VueApexCharts)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
