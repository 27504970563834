import API from '@/services/api';

export default {
  state: {
    scan_data_log: [],
    holders_data: [],
    scaned_data: [],
    high_score_data: [],
    lower_score_data: [],
    most_scan_data: [],
    final_verdict: [],
    token_data: {},
    contract_address: null,
    number_of_pages: '',
    parent_token_meta_data: null
  },
  getters: {

  },
  mutations: {
    SET_CONTRACT_SCAN_LOG(state, scan_data_log) {
      state.scan_data_log = scan_data_log;
    },
    SET_CONTRACT_SCANED_DATA(state, scaned_data) {
      state.scaned_data = scaned_data;
    },
    SET_HIG_SCORE_DATA(state, high_score_data) {
      state.high_score_data = high_score_data;
    },
    SET_LOWER_SCORE_DATA(state, lower_score_data) {
      state.lower_score_data = lower_score_data;
    },
    SET_MOST_SCAN_DATA(state, most_scan_data) {
      state.most_scan_data = most_scan_data;
    },
    SET_CONTRACT_SCANED_PAGES(state, number_of_pages) {
      state.number_of_pages = number_of_pages;
    },
    SET_CONTRACT_HOLDER_DATA(state, holders_data) {
      state.holders_data = holders_data;
    },
    SET_FINAL_VERDICT(state, final_verdict) {
      state.final_verdict = final_verdict;
    },
    SET_TOKEN_META(state, token_data) {
      state.token_data = token_data;
    },
    SET_CONTRACT_ADDRESS(state, contract_address) {
      state.contract_address = contract_address;
    },
    SET_PARENT_TOKEN_META_DATA(state, metaData) {
      state.parent_token_meta_data = metaData;
    }
  },
  actions: {

    //send the contract address to retrieve scanned result data
    FETCH_SCAN_DATA({ commit }, address) {
      return new Promise((resolve, reject) => {
        commit('loaders/SET_LOADING', true, { root: true })
        API.post("scan", address).then((response) => {
          if (response) {
            commit('SET_CONTRACT_SCAN_LOG', response.data.payload.contract_scanner_log);
            commit('SET_CONTRACT_HOLDER_DATA', response.data.payload.token_holders_data);
            commit('SET_FINAL_VERDICT', response.data.payload.final_verdict);
            commit('SET_TOKEN_META', response.data.payload.token_meta);
            commit('SET_CONTRACT_ADDRESS', response.data.payload.contract_address);
            setTimeout(() => {
              commit('loaders/SET_LOADING', false, { root: true })
            }, 1000);


          } else {
            commit('loaders/SET_LOADING', false, { root: true })
            reject();
          }
        }).catch((error) => {
          commit('loaders/SET_LOADING', false, { root: true })
          reject(error);
        })
      });
    },

    //get the scanned result using contract address
    FETCH_SCANED_RESULTS({ commit }, page) {
      return new Promise((resolve, reject) => {
        commit('loaders/SET_LOADING', true, { root: true })
        API.get(`scan/results?page=${page}`).then((response) => {
          if (response) {
            commit('SET_CONTRACT_SCANED_DATA', response.data.payload.data);

            // set scaned result total pages
            // let scanned_result_pages = Math.ceil(response.data.payload.meta.total / 20);
            commit('SET_CONTRACT_SCANED_PAGES', response.data.payload.meta.total);

            commit('loaders/SET_LOADING', false, { root: true });
          } else {
            commit('loaders/SET_LOADING', false, { root: true });
            reject();
          }
        }).catch((error) => {
          commit('loaders/SET_LOADING', false, { root: true });
          reject(error);
        })
      });
    },

    //get the scanned analytics
    FETCH_ANALYTICS_RESULTS({ commit }) {
      return new Promise((resolve, reject) => {
        commit('loaders/SET_LOADING', true, { root: true })
        API.get(`scan/analytics`).then((response) => {
          if (response) {
            commit('SET_HIG_SCORE_DATA', response.data.payload.highest.data);
            commit('SET_LOWER_SCORE_DATA', response.data.payload.lowest.data);
            commit('SET_MOST_SCAN_DATA', response.data.payload.moderate.data);
            commit('loaders/SET_LOADING', false, { root: true });
          } else {
            commit('loaders/SET_LOADING', false, { root: true });
            reject();
          }
        }).catch((error) => {
          commit('loaders/SET_LOADING', false, { root: true });
          reject(error);
        })
      });
    },

    //get the parent token meta data from the server
    FETCH_PARENT_TOKEN_DATA({ commit }) {
      commit('loaders/SET_PRICE_LOADING', true, { root: true })
      API.get(`token/price`).then((response) => {
        if (response) {
          const tokenData = response.data;
          commit('SET_PARENT_TOKEN_META_DATA', tokenData);
          commit('loaders/SET_PRICE_LOADING', false, { root: true });
        } else {
          commit('loaders/SET_PRICE_LOADING', false, { root: true });

        }
      }).catch(() => {
        commit('loaders/SET_PRICE_LOADING', false, { root: true });

      })

    }
  }
}